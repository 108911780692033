import img1 from "../Pictures/FrontPage/IMG_7292.png";
import img2 from "../Pictures/FrontPage/IMG_7200.jpg";
import img3 from "../Pictures/FrontPage/IMG_7161.png";
import img4 from "../Pictures/FrontPage/IMG_6637.jpg";
import img5 from "../Pictures/FrontPage/IMG_7167.png";
import img6 from "../Pictures/FrontPage/IMG_3784.jpg";
import img7 from "../Pictures/FrontPage/IMG_8291.jpg";
import img8 from "../Pictures/FrontPage/IMG_8297.jpg";
import img9 from "../Pictures/FrontPage/IMG_8415.jpg";
import img10 from "../Pictures/FrontPage/IMG_8535.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DefaultPage = () => {
  const ImageList = [
    { image: img1, description: "img1" },
    { image: img7, description: "img7" },
    { image: img9, description: "img9" },
    { image: img10, description: "img10" },
    { image: img8, description: "img8" },
    { image: img2, description: "img2" },
    { image: img3, description: "img3" },
    { image: img4, description: "img4" },
    { image: img5, description: "img5" },
  ];
  return (
    <Box p={2} display="grid" gridTemplateRows="auto" justifyItems="center">
      <img
        src={img6}
        alt="Front Pic"
        style={{ maxWidth: "50%", height: "auto" }}
      />
      <Box
        marginX={60}
        marginY={5}
        padding={3}
        bgcolor="#f0f8ff"
        borderRadius={8}
        boxShadow={3}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to <strong>ELF Microgreens!</strong>
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          I'm Erika L. Forsythe <em>[ELF]</em>.
        </Typography>
        <Typography variant="body1" paragraph>
          As a Chef and/or Restaurant Owner/Manager, you'll discover our dynamic
          Flavor List and Order Form. Plus, don't miss the chance to explore our
          Chef and customer foodie photos, and get to know more about me and our
          business philosophy at <strong>The ELF Enchanted Garden LLC</strong>.
        </Typography>
      </Box>

      {ImageList.map((image) => {
        return (
          <div>
            <h1>{image.title}</h1>
            <img
              src={image.image}
              alt="Blog"
              style={{ maxWidth: "50%", height: "auto" }}
            />
          </div>
        );
      })}
    </Box>
  );
};

export default DefaultPage;
