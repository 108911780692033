import React from "react";
import { Stage, Layer, Text, Rect } from "react-konva";
import img1 from "../Pictures/Contact/IMG_8442.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";

const ContactPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const phoneNumber = "410-430-6924";
  const email = "elfmomentsofzen@gmail.com";

  const boxWidth = isSmallScreen ? 300 : 600;
  const boxHeight = 150;

  return (
    <>
      <Box
        marginX="auto"
        marginY={5}
        padding={3}
        bgcolor="#f0f8ff"
        borderRadius={8}
        boxShadow={3}
        width={boxWidth}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Contact Me!
          </Typography>
          <Stage width={boxWidth} height={boxHeight}>
            <Layer>
              <Rect
                x={0}
                y={0}
                width={boxWidth}
                height={boxHeight}
                fill="#f0f8ff"
              />
              <Text
                x={boxWidth / 2}
                y={boxHeight / 2 - 20}
                text={phoneNumber}
                fontSize={24}
                fill="black"
                align="center"
                offsetX={phoneNumber.length * 6}
              />
              <Text
                x={boxWidth / 2}
                y={boxHeight / 2 + 20}
                text={email}
                fontSize={24}
                fill="black"
                align="center"
                offsetX={email.length * 6}
              />
            </Layer>
          </Stage>
        </Box>
      </Box>
      <img
        src={img1}
        alt="Front Pic"
        style={{ maxWidth: "50%", height: "auto" }}
      />
    </>
  );
};

export default ContactPage;
