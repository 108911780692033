import React from 'react';
import AboutMeProfile from "../Pictures/AboutMe/AboutMeProfile.jpg";
import AboutMe from "../Pictures/AboutMe/AboutMe.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { useTheme, useMediaQuery } from '@mui/material';

const AboutMePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const boxWidth = isSmallScreen ? '90%' : '50%';
  
  return (
    <div>
      <Box marginTop={5} display="flex" justifyContent="center">
        <img
          src={AboutMe}
          alt="About Me"
          style={{ maxWidth: "25%", height: "auto" }}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box
          marginX={isSmallScreen ? 2 : 20}
          marginTop={5}
          padding={3}
          bgcolor="#f0f8ff"
          borderRadius={8}
          boxShadow={3}
          width={boxWidth}
        >
          <Typography variant="body1" component="p" paragraph>
            ELF Microgreens are superfood mini plants with bold flavor that are
            grown hydroponically on an indoor vertical grow space with lots of
            happy vibes and no pesticides. Erika's 23-acre, African-American,
            woman-owned farm is located here on Maryland's Eastern Shore. She
            believes in cultivating wellness practices as part of her everyday
            lifestyle and invites you to experience ELF Microgreens as a daily
            Wellness Win. #MiniPlantsMightyFlavor.
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center" p={2}>
        <Box display="flex" justifyContent="center">
          <img
            src={AboutMeProfile}
            alt="Profile"
            style={{ maxWidth: "25%", height: "auto" }}
          />
        </Box>
        <Box
          marginTop={5}
          padding={3}
          bgcolor="#f0f8ff"
          borderRadius={8}
          boxShadow={3}
          width={boxWidth}
          textAlign="left"
          marginX="auto"
        >
          <Typography variant="h5" component="h1" textAlign="center">
            🧝🏽‍♀️ Owner | ELF Enchanted Garden | Superfood Microgreen Mini Plant Farm
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            My lifestyle has included hosting a community news show that aired on CNN Headline News Channel 📺,
            <br /> becoming a state-certified jam artisan 🍋 with 14 original flavors, and growing up on the tennis circuit.
            <br /> 🎾 Georgia Tech honored me with the Total Person Award 🥇 and named me one of the university’s greatest African American
            Scholar-Athletes 🤓 of all time.
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            I am a:
          </Typography>
          <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <List style={{ listStyleType: "none", padding: 0 }}>
              <ListItem>🍵 Gourmet tea snob</ListItem>
              <ListItem>🧘🏽‍♀️ Yoga enthusiast</ListItem>
              <ListItem>📚 Novel reader</ListItem>
              <ListItem>🍷 Wine lover</ListItem>
              <ListItem>🎷 Jazz fan</ListItem>
              <ListItem>⛰️ Jeeper with off-road badges</ListItem>
              <ListItem>📸 Photographer with 4 international competition award wins & 3 blue ribbon art gallery awards</ListItem>
              <ListItem>🎾 Top 60 all-time Georgia Tech scholar-athlete</ListItem>
              <ListItem>❤️ 13th generation descendant of Pocahontas</ListItem>
              <ListItem>📺 Former host of a show airing on CNN/HN</ListItem>
              <ListItem>🌱 Microgreens company owner & grower with 23 acres 🌎 who has visited 37 countries</ListItem>
              <ListItem>🧚🏽‍♀️ Wife of a Chemical Engineering PhD</ListItem>
              <ListItem>🦹🏽‍♀️ Mom of two Software Developer sons</ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AboutMePage;
