import Publicity1 from "../Pictures/Publicity/Publicity1.jpg";
import Publicity2 from "../Pictures/Publicity/Publicity2.jpg";
import Publicity3 from "../Pictures/Publicity/Publicity3.jpg";
import Publicity4 from "../Pictures/Publicity/Publicity4.jpg";
import Publicity5 from "../Pictures/Publicity/Publicity5.jpg";
import Box from "@mui/material/Box";

const Publicity = () => {
  const publicityImages = [
    { name: "publicityimg1", image: Publicity1 },
    { name: "publicityimg2", image: Publicity2 },
    { name: "publicityimg3", image: Publicity3 },
    { name: "publicityimg4", image: Publicity4 },
    { name: "publicityimg5", image: Publicity5 },
  ];
  return (
    <Box p={2} display="grid" gridTemplateRows="auto" justifyItems="center">
      {publicityImages.map((image, index) => (
        <img
          key={index}
          src={image.image}
          alt="Food"
          style={{ maxWidth: "25%", height: "auto", marginBottom: "10px" }}
        />
      ))}
    </Box>
  );
};

export default Publicity;
