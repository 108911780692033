import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from 'react-redux';

const FlavorList = () => {
  const microGreenList = useSelector((state) => state.microGreenList);

  return microGreenList.map((image, index) => {
    const isEven = index % 2 === 0;
    return (
      <Grid
        marginTop={5}
        container
        justifyContent="center"
        key={index}
        style={{ width: "100%" }}
      >
        <Grid item xs={6}>
          <Grid container>
            {isEven ? (
              <>
                <Grid item xs={7}>
                  <Box textAlign="left" pl={1} pr={1}>
                    <img
                      src={image.picture}
                      alt="Logo"
                      style={{ maxWidth: "75%", height: "auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    height="100%"
                    ml={1} // Add some margin-left to move text closer to the image
                  >
                    {image.description}
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={5}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    height="100%"
                    mr={1} // Add some margin-right to move text closer to the image
                  >
                    {image.description}
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Box textAlign="right" pl={1} pr={1}>
                    <img
                      src={image.picture}
                      alt="Logo"
                      style={{ maxWidth: "75%", height: "auto" }}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    );
  });
};

export default FlavorList;
