import FoodiePhotosPage from "./FoodiePhotosPage";
import FlavorListPage from "./FlavorListPage";
import OrderPage from "./OrderPage";
import RecipesPage from "./RecipesPage";
import AboutMePage from "./AboutMePage";
import ContactPage from "./ContactPage";
import Publicity from "./Publicity";
import DefaultPage from "./DefaultPage";
import FarmersMarketPage from "./FarmersMarketPage";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMicroGreenList,
  setShowLoginPopup,
  setShowSignupPopup,
  setFirstName,
  setLastName,
  setUserName,
  setPassWord,
  setPhoneNumber,
  setIsLoggedIn,
  setValidCustomer,
  setUserOrganization,
  setWholeSaleGreenList,
} from "../../store/dashboard";
import "./MainPage.css";
import { auth } from "../FirebaseConnection/FirebaseConfig";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import LoadGreens from "./LoadGreens";

const MainPage = () => {
  const currentPage = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const showLoginPopup = useSelector((state) => state.showLoginPopup);
  const showSignupPopup = useSelector((state) => state.showSignupPopup);
  const userName = useSelector((state) => state.userName);
  const passWord = useSelector((state) => state.passWord);
  const userOrganization = useSelector((state) => state.userOrganization);
  const firstName = useSelector((state) => state.firstName);
  const lastName = useSelector((state) => state.lastName);
  const phoneNumber = useSelector((state) => state.phoneNumber);

  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [resetEmail, setResetEmail] = useState(""); // State to store the email for password reset
  const [error, setError] = useState(null);
  const [failedLogin, setFailedLogin] = useState(false);

  const togglePopup = () => {
    dispatch(setShowLoginPopup(false));
    dispatch(setShowSignupPopup(false));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        userName,
        passWord
      );
      // Signed in successfully
      const user = userCredential.user;
      dispatch(setIsLoggedIn(true));
      setFailedLogin(false);
      togglePopup();
      // You can redirect or perform additional actions upon successful login
    } catch (error) {
      // Handle login errors (e.g., incorrect password, user not found)
      setFailedLogin(true);
      console.error("Error signing in:", error.message);
      // Optionally, show an error message to the user
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        signupEmail,
        signupPassword
      );
      // Signed up successfully
      const user = userCredential.user;
      togglePopup();
      // Optionally, you can redirect the user to another page after successful sign-up
    } catch (error) {
      setError(error.message);
      console.error("Error signing up:", error.message);
    }
  };

  // Function to handle password reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail); // Send password reset email
      alert("Password reset email sent! Check your email inbox."); // Inform user about password reset email
    } catch (error) {
      setError(error.message);
      console.error("Error sending password reset email:", error.message);
    }
  };

  useEffect(() => {
    const microgreenList = LoadGreens();
    dispatch(setWholeSaleGreenList(microgreenList[0]));
    dispatch(setMicroGreenList(microgreenList[1]));
  }, [dispatch]);

  return (
    <div>
      {currentPage === "foodie photos" && <FoodiePhotosPage />}
      {currentPage === "flavor list" && <FlavorListPage />}
      {currentPage === "flavor profiles & order form" && <OrderPage />}
      {currentPage === "flavor profiles" && <OrderPage />}
      {currentPage === "farmers market" && <FarmersMarketPage />}
      {currentPage === "recipes" && <RecipesPage />}
      {currentPage === "about me" && <AboutMePage />}
      {currentPage === "contact" && <ContactPage />}
      {currentPage === "publicity" && <Publicity />}
      {currentPage === "" && <DefaultPage />}
      {showLoginPopup && (
        <div className="modal">
          {/* Modal content */}
          <div className="modal-content">
            <span className="close" onClick={togglePopup}>
              &times;
            </span>
            <h2>Login</h2>
            {/* Your login form goes here */}
            <form onSubmit={handleLoginSubmit}>
              <label>Email:</label>
              <input
                type="text"
                value={userName}
                onChange={(e) => dispatch(setUserName(e.target.value))}
                required
              />
              <label>Password:</label>
              <input
                type="password"
                value={passWord}
                onChange={(e) => dispatch(setPassWord(e.target.value))}
                required
              />
              <button type="submit">Login</button>
              {failedLogin && <p style={{ color: "red" }}>Invalid Login</p>}
              <p>Forgot Password?</p>{" "}
              {/* Add a link or button for forgot password */}
              <input
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <button onClick={handleResetPassword}>Reset Password</button>
            </form>
          </div>
        </div>
      )}
      {/* Signup Popup */}
      {showSignupPopup && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={togglePopup}>
              &times;
            </span>
            <h2>Sign Up</h2>
            <form onSubmit={handleSignUpSubmit}>
              <label>First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => dispatch(setFirstName(e.target.value))}
                required
              />
              <label>Last Name:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => dispatch(setLastName(e.target.value))}
                required
              />
              <label>Organization:</label>
              <input
                type="organization"
                value={userOrganization}
                onChange={(e) => dispatch(setUserOrganization(e.target.value))}
                required
              />
              <label>Email:</label>
              <input
                type="email"
                value={signupEmail}
                onChange={(e) => setSignupEmail(e.target.value)}
                required
              />
              <label>Password:</label>
              <input
                type="password"
                value={signupPassword}
                onChange={(e) => setSignupPassword(e.target.value)}
                required
              />
              <label>Phone Number:</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => dispatch(setPhoneNumber(e.target.value))}
                required
              />
              <button type="submit">Sign Up</button>
            </form>
            {error && <p>{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainPage;
