import wasabi from "../Pictures/Food Pictures/wasabi.jpg";
import taco from "../Pictures/Food Pictures/taco.jpg";
import sushi from "../Pictures/Food Pictures/sushi.jpg";
import smoothie from "../Pictures/Food Pictures/smoothie.jpg";
import sandwich from "../Pictures/Food Pictures/sandwich.jpg";
import saladbowl from "../Pictures/Food Pictures/saladbowl.jpg";
import chickensandwich from "../Pictures/Food Pictures/chickensandwich.jpg";
import corntortilla from "../Pictures/Food Pictures/corntortilla.jpg";
import deviledegg from "../Pictures/Food Pictures/deviledegg.jpg";
import eggsalad from "../Pictures/Food Pictures/eggsalad.jpg";
import fruitbowl from "../Pictures/Food Pictures/fruitbowl.jpg";
import GreenBowl from "../Pictures/Food Pictures/GreenBowl.jpg";
import greensandegg from "../Pictures/Food Pictures/greensandegg.jpg";
import purplesmoothie from "../Pictures/Food Pictures/purplesmoothie.jpg";
import sushi1 from "../Pictures/Food Pictures/sushi1.jpg";
import tofumaybe from "../Pictures/Food Pictures/tofumaybe.jpg";
import willmapp from "../Pictures/Food Pictures/willmapp.jpg";
import chicken from "../Pictures/Food Pictures/chicken.jpg";
import brownie from "../Pictures/Food Pictures/brownie.jpg";
import breadbowl from "../Pictures/Food Pictures/breadbowl.jpg";
import bowl from "../Pictures/Food Pictures/bowl.jpg";
import BigBurger from "../Pictures/Food Pictures/BigBurger.jpg";
import avotoast from "../Pictures/Food Pictures/avotoast.jpg";
import Crabdish from "../Pictures/Food Pictures/Crabdish.jpg";
import Hotdog from "../Pictures/Food Pictures/Hotdog.jpg";
import Fancy from "../Pictures/Food Pictures/Fancy.jpg";
import RadishSandwich from "../Pictures/Food Pictures/RadishSandwich.jpg";
import FoodiePic_20 from "../Pictures/Food Pictures/FoodiePic_20.jpg";
import FoodiePic_21 from "../Pictures/Food Pictures/FoodiePic_21.jpg";
import IMG_7188 from "../Pictures/Food Pictures/IMG_7188.jpg";
import IMG_7202 from "../Pictures/Food Pictures/IMG_7202.jpg";
import IMG_7223 from "../Pictures/Food Pictures/IMG_7223.jpg";
import IMG_7261 from "../Pictures/Food Pictures/IMG_7261.jpg";
import IMG_7263 from "../Pictures/Food Pictures/IMG_7263.jpg";
import Aug100 from "../Pictures/Food Pictures/Aug100.jpg";
import Aug101 from "../Pictures/Food Pictures/Aug101.jpg";
import Aug102 from "../Pictures/Food Pictures/Aug102.jpg";
import Aug103 from "../Pictures/Food Pictures/Aug103.jpg";

import Box from "@mui/material/Box";

// TODO load new photos in
const FoodiePhotosPage = () => {
  const foodImages = [
    { name: Fancy },
    { name: Aug100 },
    { name: Aug101 },
    { name: Aug102 },
    { name: Aug103 },
    { name: BigBurger },
    { name: eggsalad },
    { name: avotoast },
    { name: FoodiePic_20 },
    { name: sushi1 },
    { name: purplesmoothie },
    { name: willmapp },
    { name: deviledegg },
    { name: corntortilla },
    { name: wasabi },
    { name: taco },
    { name: sushi },
    { name: smoothie },
    { name: sandwich },
    { name: saladbowl },
    { name: chickensandwich },
    { name: chicken },
    { name: RadishSandwich },
    { name: FoodiePic_21 },
    { name: brownie },
    { name: breadbowl },
    { name: bowl },
    { name: sushi },
    { name: tofumaybe },
    { name: fruitbowl },
    { name: GreenBowl },
    { name: greensandegg },
    { name: Crabdish },
    { name: Hotdog },
    { name: IMG_7188 },
    { name: IMG_7202 },
    { name: IMG_7223 },
    { name: IMG_7261 },
    { name: IMG_7263 },
  ];

  return (
    <div>
      <h1>Chef & Customer Photos of Dishes Featuring ELF Microgreens</h1>
      <Box p={2} display="grid" gridTemplateRows="auto" justifyItems="center">
        {foodImages.map((image, index) => (
          <img
            key={index}
            src={image.name}
            alt="Food"
            style={{ maxWidth: "25%", height: "auto", marginBottom: "10px" }}
          />
        ))}
      </Box>
    </div>
  );
};

export default FoodiePhotosPage;
