import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentPage,
  setValidCustomer,
  setIsLoggedIn,
  setShowLoginPopup,
  setShowSignupPopup,
} from "../../store/dashboard";
import logocroppedsquare from "../Pictures/AboutMe/logocroppedsquare.jpg";

const userPages = [
  "Foodie Photos",
  "Flavor Profiles",
  "Farmers Market",
  "Recipes",
  "Publicity",
  "About Me",
  "Contact",
];
const customerPages = [
  "Foodie Photos",
  "Flavor Profiles & Order Form",
  "Farmers Market",
  "Recipes",
  "Publicity",
  "About Me",
  "Contact",
];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [pages, setPages] = React.useState(customerPages);
  const showLoginPopup = useSelector((state) => state.showLoginPopup);
  const showSignupPopup = useSelector((state) => state.showSignupPopup);
  const validCustomer = useSelector((state) => state.validCustomer);
  const verificationCode = useSelector((state) => state.verificationCode);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const [loginOption, setLoginOption] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [isTokenInvalid, setIsTokenInvalid] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (validCustomer) {
      setPages(customerPages);
    } else {
      setPages(userPages);
    }
  }, [validCustomer]);

  const handleChange = (event) => {
    const chosenValue = event.target.value;
    setLoginOption(chosenValue);
    if (chosenValue === "Login") {
      dispatch(setShowLoginPopup(true));
    }
    if (chosenValue === "SignUp") {
      dispatch(setShowSignupPopup(!showSignupPopup));
    }
    if (chosenValue === "SignOut") {
      dispatch(setValidCustomer(false));
      dispatch(updateCurrentPage(""));
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    dispatch(updateCurrentPage(event.target.innerText.toLowerCase()));
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleVerify = () => {
    if (inputValue === verificationCode) {
      dispatch(setValidCustomer(true));
      setIsTokenInvalid(false);
    } else {
      setIsTokenInvalid(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleVerify();
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#75A130",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={logocroppedsquare}
            alt="Logo"
            style={{ maxWidth: "3%", height: "auto" }}
          />

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                fontFamily: "Georgia",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ELF Microgreens
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href=""
              sx={{
                fontFamily: "Georgia",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Home
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} value={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Georgia",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ELF Microgreens
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  borderRight:
                    index < pages.length - 1
                      ? "1px solid rgba(255, 255, 255, 0.3)"
                      : "none",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {isLoggedIn && !validCustomer && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                label="Enter Premium Token"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                error={isTokenInvalid}
                helperText={isTokenInvalid ? "Invalid token" : ""}
                sx={{
                  mr: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: isTokenInvalid ? "red" : "inherit",
                    },
                    "&:hover fieldset": {
                      borderColor: isTokenInvalid ? "red" : "inherit",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: isTokenInvalid ? "red" : "inherit",
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                onClick={handleVerify}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                Verify
              </Button>
            </Box>
          )}

          {!isLoggedIn ? (
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel>Restaurant Only Login</InputLabel>
              <Select value={loginOption} onChange={handleChange}>
                <MenuItem value={"Login"}>Login</MenuItem>
                <MenuItem value={"SignUp"}>SignUp</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel>Restaurant Only Login</InputLabel>
              <Select value={loginOption} onChange={handleChange}>
                <MenuItem value={"SignOut"}>SignOut</MenuItem>
              </Select>
            </FormControl>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
