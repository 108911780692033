import React, { useEffect, useState } from "react";
import "./OrderPage.css";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import emailjs from "emailjs-com";
import Beet from "../Pictures/FlavorList/Beet.jpg";
import RedBeet from "../Pictures/FlavorList/RedBeet.jpg";

const OrderPage = () => {
  const products = useSelector((state) => state.microGreenList);
  const wholeSaleProducts = useSelector((state) => state.wholeSaleGreenList);
  const [selectedQuantities, setSelectedQuantities] = useState(
    Array(products.length).fill("0")
  );
  const [wholeSaleSelectedQuantities, setWholeSaleSelectedQuantities] =
    useState(Array(wholeSaleProducts.length).fill("0"));
  const pricingArray = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
  ];
  const wholeSalePricingArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [userMessage, setUserMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productGrandTotal, setProductGrandTotal] = useState(0);
  const [wholeSaleGrandTotal, setWholeSaleGrandTotal] = useState(0);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [cooldown, setCooldown] = useState(0);

  const validCustomer = useSelector((state) => state.validCustomer);
  const deliveryFeePerOrder = 5;
  const recipientEmail = "elfenchantedgarden@gmail.com";
  const subject = "Order Request";

  const userOrganization = useSelector((state) => state.userOrganization);
  const userName = useSelector((state) => state.userName);

  const handleQuantityChange = (index, event) => {
    const newQuantities = [...selectedQuantities];
    newQuantities[index] = event.target.value;
    setSelectedQuantities(newQuantities);
  };

  const handleWholeSaleQuantityChange = (index, event) => {
    const newQuantities = [...wholeSaleSelectedQuantities];
    newQuantities[index] = event.target.value;
    setWholeSaleSelectedQuantities(newQuantities);
  };

  const handleCompanyChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleUserMessageChange = (event) => {
    setUserMessage(event.target.value);
  };

  const calculateTotalPrice = (quantities, products) => {
    const total = products.reduce((acc, product, index) => {
      const quantity = parseInt(quantities[index]) || 0;
      const productTotal = parseInt(product.price) * quantity;
      return acc + productTotal;
    }, 0);
    return total + deliveryFeePerOrder;
  };

  useEffect(() => {
    setProductGrandTotal(calculateTotalPrice(selectedQuantities, products));
    setWholeSaleGrandTotal(
      calculateTotalPrice(wholeSaleSelectedQuantities, wholeSaleProducts)
    );
  }, [
    selectedQuantities,
    wholeSaleSelectedQuantities,
    products,
    wholeSaleProducts,
  ]);

  const formatProducts = (products, quantities) => {
    return products
      .map((product, index) => {
        const quantity = parseInt(quantities[index]);
        if (quantity > 0) {
          const totalPrice = (parseInt(product.price) * quantity).toFixed(2);
          return `Product: ${product.name}, Quantity: ${quantity} oz, Price: $${product.price}, Total Price: $${totalPrice}`;
        }
        return null;
      })
      .filter((item) => item !== null)
      .join("\n");
  };

  const formatWholeSaleProducts = (products, quantities) => {
    return products
      .map((product, index) => {
        const quantity = parseInt(quantities[index]);
        if (quantity > 0) {
          const totalPrice = (parseInt(product.price) * quantity).toFixed(2);
          return `Product: ${product.name}, Quantity: ${quantity} trays, Price: $${product.price}, Total Price: $${totalPrice}`;
        }
        return null;
      })
      .filter((item) => item !== null)
      .join("\n");
  };

  const resetForm = () => {
    setSelectedQuantities(Array(50).fill("0"));
    setWholeSaleSelectedQuantities(Array(50).fill("0"));
    setUserMessage("");
    setCompanyName("");
    setPhoneNumber("");
    setProductGrandTotal(0);
    setWholeSaleGrandTotal(0);
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    if (isEmailSent) {
      alert("Please wait before sending another email.");
      return;
    }

    const formattedProducts = formatProducts(products, selectedQuantities);
    const formattedWholeSaleProducts = formatWholeSaleProducts(
      wholeSaleProducts,
      wholeSaleSelectedQuantities
    );
    console.log(formattedProducts);
    const templateParams = {
      to_name: "ELF",
      to_email: recipientEmail,
      from_email: userName,
      from_name: userName,
      company: userOrganization,
      subject: subject,
      message: `\nName: ${userName}\nCompany: ${companyName}\nEmail: ${userName}\nPhoneNumber: ${phoneNumber}\nMessage: ${userMessage}\n\nOrder Details:\n${formattedProducts}\n${formattedWholeSaleProducts}\n\nGrand Total: $${
        productGrandTotal + wholeSaleGrandTotal
      }`,
    };

    emailjs
      .send(
        "service_mpjlnb8",
        "template_hcxvooj",
        templateParams,
        "_7Q51mO8NLuDAIgqL"
      )
      .then(
        (response) => {
          alert("Email sent successfully");
          setIsEmailSent(true);
          setCooldown(60); // Set cooldown period in seconds
          resetForm(); // Reset the form after successful email submission
        },
        (error) => {
          console.error("Failed to send email:", error);
          alert("Error sending email: " + error.text);
        }
      );
  };

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(cooldown - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (cooldown === 0 && isEmailSent) {
      setIsEmailSent(false);
    }
  }, [cooldown, isEmailSent]);

  return (
    <div className="order-form">
      <h2>ELF Microgreens Flavor Profiles</h2>
      <table>
        <thead>
          <tr>
            <th>Flavor Name</th>
            <th>Product Photo</th>
            <th>Description</th>
            {validCustomer && (
              <>
                <th>Quantity</th>
                <th>Wholesale Price Per Tray</th>
                <th>Total Price</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {wholeSaleProducts.map((product, index) => {
            const quantity = parseInt(wholeSaleSelectedQuantities[index]);
            const totalPrice = `$${(parseInt(product.price) * quantity).toFixed(
              2
            )}`;
            return (
              <tr key={index}>
                <td>{product.name}</td>
                <td>
                  <img
                    src={product.picture}
                    alt={product.name}
                    style={{
                      width: "100px",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                </td>
                <td>{product.description}</td>
                {validCustomer && (
                  <>
                    <td>
                      <select
                        value={wholeSaleSelectedQuantities[index]}
                        onChange={(event) =>
                          handleWholeSaleQuantityChange(index, event)
                        }
                      >
                        {wholeSalePricingArray.map((quantity) => (
                          <option key={quantity} value={quantity}>
                            {quantity} tray(s)
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>${product.price}</td>
                    <td>{totalPrice}</td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <table>
        <thead>
          <tr>
            <th>Flavor Name</th>
            <th>Product Photo</th>
            <th>Description</th>
            {validCustomer && (
              <>
                <th>Quantity</th>
                <th>Wholesale Price Per Ounce</th>
                <th>Total Price</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => {
            const quantity = parseInt(selectedQuantities[index]);
            const totalPrice = `$${(parseInt(product.price) * quantity).toFixed(
              2
            )}`;
            return (
              <tr key={index}>
                <td>{product.name}</td>
                <td>
                  {product.name === "ZEN Beets Confetti" ? (
                    <div>
                      <img
                        src={RedBeet}
                        alt="Red Beet"
                        style={{
                          width: "100px",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                      <img
                        src={Beet}
                        alt="Beet"
                        style={{
                          width: "100px",
                          height: "auto",
                          marginBottom: "10px",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={product.picture}
                      alt={product.name}
                      style={{
                        width: "100px",
                        height: "auto",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                </td>
                <td>{product.description}</td>
                {validCustomer && (
                  <>
                    <td>
                      <select
                        value={selectedQuantities[index]}
                        onChange={(event) => handleQuantityChange(index, event)}
                      >
                        {pricingArray.map((quantity) => (
                          <option key={quantity} value={quantity}>
                            {quantity} oz
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>${product.price}</td>
                    <td>{totalPrice}</td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      {validCustomer && (
        <>
          <TextField
            label="Company Name"
            variant="outlined"
            value={companyName}
            onChange={handleCompanyChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={userMessage}
            onChange={handleUserMessageChange}
            fullWidth
            margin="normal"
          />
          <div className="order-summary">
            <span>Note: Delivery Fee ${deliveryFeePerOrder}.00</span>
            <span className="total-price">
              Total Price: ${productGrandTotal + wholeSaleGrandTotal}
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
              disabled={
                !companyName ||
                !phoneNumber ||
                isEmailSent ||
                (productGrandTotal < 6 && wholeSaleGrandTotal < 25)
              }
            >
              Send Email
            </Button>
            {isEmailSent && (
              <span>
                Please wait {cooldown} seconds before sending another email.
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderPage;
