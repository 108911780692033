import "./App.css";
import NavBar from "./components/Navbar/NavBar";
import MainPage from "./components/Pages/MainPage";
import sandwich from "./components/Pictures/Food Pictures/sandwich.jpg";
import CilantroBackground from "./components/Pictures/Background/CilantroBackground.png";
import FoodieGallery from "./components/FirebaseConnection/getFoodiePhotosImages";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";

function App() {
  const currentPage = useSelector((state) => state.currentPage);
  return (
    <div className="App" style={{ position: "relative", minHeight: "100vh" }}>
      <FoodieGallery />
      {currentPage === "foodie photos" || currentPage === "flavor list" ? (
        <Box
          sx={{
            backgroundImage: `url(${sandwich})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100%",
            height: "100%",
            position: "absolute",
            opacity: "25%",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url(${CilantroBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            width: "100%",
            height: "100%",
            position: "absolute",
            opacity: "25%",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
      )}

      <div style={{ position: "relative", zIndex: 1 }}>
        <NavBar />
        <MainPage />
      </div>
    </div>
  );
}

export default App;
