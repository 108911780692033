import { configureStore, createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  currentPage: "",
  verificationCode: "elf24",
  foodiePhotosList: [],
  microGreenList: [],  
  wholeSaleGreenList: [],  
  showLoginPopup: false,
  showSignupPopup: false,
  firstName: "",
  lastName: "",
  userName: "",
  passWord: "",
  phoneNumber: "",
  userOrganization: "",
  isLoggedIn: false,
  validCustomer: "",
};

// Create a slice for managing state and reducers
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },    
    setFoodiePhotosList: (state, action) => {
      state.foodiePhotosList = action.payload;
    },
    setMicroGreenList: (state, action) => {
      state.microGreenList = action.payload;
    },
    setWholeSaleGreenList: (state, action) => {
      state.wholeSaleGreenList = action.payload;
    },
    setShowLoginPopup: (state, action) => {
      state.showLoginPopup = action.payload;
    },
    setShowSignupPopup: (state, action) => {
      state.showSignupPopup = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setValidCustomer: (state, action) => {
      state.validCustomer = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setPassWord: (state, action) => {
      state.passWord = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setUserOrganization: (state, action) => {
      state.userOrganization = action.payload;
    },
    // Add more reducer functions for additional variables
  },
});

// Extract reducer and actions from the 
export const { updateCurrentPage, setFoodiePhotosList, setMicroGreenList, setWholeSaleGreenList,setIsLoggedIn, setValidCustomer, setShowLoginPopup, setShowSignupPopup, setFirstName, setLastName, setUserName, setPassWord, setPhoneNumber, setUserOrganization} = dashboardSlice.actions;

// Create Redux store
const store = configureStore({
  reducer: dashboardSlice.reducer,
});

export default store;
