import img1 from "../Pictures/FarmersMarket/IMG_8227.jpg";
import img2 from "../Pictures/FarmersMarket/IMG_8240.png";
import img3 from "../Pictures/FarmersMarket/IMG_8243.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
const FarmersMarketPage = () => {
  const farmersMarketPicList = [
    { title: "ELF Microgreens", image: img2, description: "Image 2" },
    { title: "ELF Microgreens", image: img3, description: "Image 3" },
  ];
  return (
    <Box p={2} display="grid" gridTemplateRows="auto" justifyItems="center">
      <img
        src={img1}
        alt="Front Pic"
        style={{ maxWidth: "50%", height: "auto" }}
      />
      <Box
        marginX={60}
        marginY={5}
        padding={3}
        bgcolor="#f0f8ff"
        borderRadius={8}
        boxShadow={3}
      >
        <Typography variant="body1" paragraph>
          ELF Microgreens are available with "Happy Vibes and No Pesticides" at
          the Berlin, Maryland Farmers Market. Sundays 9:00 - 1:00 EST from May
          through September. This is a "producers only" market where everyone
          must make/grow everything in their pop up shop!
        </Typography>
      </Box>

      {farmersMarketPicList.map((image) => {
        return (
          <div>
            <img
              src={image.image}
              alt="Blog"
              style={{ maxWidth: "50%", height: "auto" }}
            />
          </div>
        );
      })}
    </Box>
  );
};

export default FarmersMarketPage;
