import Box from "@mui/material/Box";
import "./RecipesPage.css";
import smoothie from "../Pictures/Food Pictures/smoothie.jpg";
import wasabi from "../Pictures/Food Pictures/wasabi.jpg";
// TODO change style of recipe
const RecipesPage = () => {
  return (
    <div>
      <Box
        sx={{
          border: 1,
          borderRadius: "8px",
          boxShadow:
            "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
          marginX: 40,
          marginTop: 5,
          padding: 2,
          backgroundColor: "#ffffff",
          "&:hover": {
            boxShadow:
              "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h1 className="title">ELF Microgreens</h1>
        <h3 className="title">#MiniPlantsMightyFlavor</h3>
        <h2 className="text">Veggie Mix Smoothie Recipe</h2>
        <p className="text">SO EASY with ONLY 4 INGREDIENTS</p>
        <p className="title">1 oz. Veggie Mix ELF Microgreens</p>
        <p className="title">
          1/2 cup Vanilla Oat or Soy Milk [or more for desired consistency]
        </p>
        <p className="title">1/2 cup Mango Chunks - FROZEN</p>
        <p className="title">1/4 cup Banana Chunks - FROZEN</p>
        <p className="title">Add <span className="text">Veggie Mix ELF Microgreens</span>, milk, mango, and banana chunks to blender. Blend until smooth. Enjoy
          NOW!</p>
        <img
          src={smoothie}
          alt="Veggie Mix Smoothie"
          style={{ maxWidth: "25%", height: "auto" }}
        />
      </Box>
      <Box
        sx={{
          border: 1,
          borderRadius: "8px",
          boxShadow:
            "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
          marginX: 40,
          marginTop: 5,
          padding: 2,
          backgroundColor: "#ffffff",
          "&:hover": {
            boxShadow:
              "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h1 className="title">ELF Microgreens</h1>
        <h3 className="title">#MiniPlantsMightyFlavor</h3>
        <h2 className="text">Wasabi Sandwich Spread / Dip Recipe</h2>
        <p className="text">1 oz. Wasabi ELF Microgreens</p>
        <p className="title">1 medium, ripe avocado, prepped w/no seed and no peel</p>
        <p className="title">1 large lemon, squeezed, juice only</p>
        <p className="title">1 smidgen* cayenne pepper [fine ground]</p>
        <p className="title">1 smidgen* lite salt</p>
        <p className="title">1/4 cup + 1 Tbsp extra virgin olive oil [EVOO]</p>
        <p className="title">Add avocado, <span className="text">ELF Microgreens</span>, lemon juice, cayenne, salt and EVOO to
        blender. Blend until smooth. Enjoy NOW!</p>
        <p className="title">* NOTE 1 smidgen = ½ pinch = 1/32 teaspoon</p>
        <img
          src={wasabi}
          alt="Wasabi Sandwich Spread"
          style={{ maxWidth: "25%", height: "auto" }}
        ></img>
      </Box>
    </div>
  );
};

export default RecipesPage;
