// src/components/ImageGallery.js
import { useEffect } from "react";
import { db } from "./FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { setFoodiePhotosList } from "../../store/dashboard";

const FoodieGallery = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "FoodiePhotos"));
        const imagesData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            return { ...data };
          })
        );
        dispatch(setFoodiePhotosList(imagesData));
      } catch (error) {
        console.error("Error fetching images:", error);
        if (error.code === "permission-denied") {
          console.error("Check your Firestore and Storage rules.");
        }
      }
    };
    fetchImages();
  }, [dispatch]);
};

export default FoodieGallery;
