import wasabiplaceholder from "../Pictures/Food Pictures/wasabiPlaceholder.jpg";
import purpleReignRadish from "../Pictures/FlavorList/PurpleReignRadish.jpg";
import AsianFusion from "../Pictures/FlavorList/AsianFusion.jpg";
import WellnessMix from "../Pictures/FlavorList/WellnessMix.jpg";
import DragonsLairRadish from "../Pictures/FlavorList/DragonsLairRadish.jpg";
// import EasternShoreSpecial from "../Pictures/FlavorList/EasternShoreSpecial.jpg";
import Cilantro from "../Pictures/FlavorList/Cilantro.jpg";
import SandwichStuffer from "../Pictures/FlavorList/SandwichStuffer.jpg";
import PurpleKohlrabi from "../Pictures/FlavorList/PurpleKohlrabi.jpg";
import SaladMix from "../Pictures/FlavorList/SaladMix.jpg";
import SouthernHospitality from "../Pictures/FlavorList/SouthernHospitality.jpg";
// import ThePerfectTopping from "../Pictures/FlavorList/ThePerfectTopping.jpg";
import VeggieMix from "../Pictures/FlavorList/VeggieMix.jpg";
import Beet from "../Pictures/FlavorList/Beet.jpg";
import ItalianBasil from "../Pictures/FlavorList/ItalianBasil.png";
import LemonBasil from "../Pictures/FlavorList/LemonBasil.png";
import LittleGemLettuce from "../Pictures/FlavorList/LittleGemLettuce.jpg";
import TendrilP from "../Pictures/FlavorList/TendrilP.jpg";
// import RedBeet from "../Pictures/FlavorList/RedBeet.jpg";

const LoadGreens = () => {
  const flavorPicList = [
    [
      {
        key: 1,
        picture: Cilantro,
        price: "25",
        totalPrice: "0",
        quantity: "0",
        name: "Cilantro",
        description:
          "These vibrant tender minis provide a concentrated burst of the unmistakeable cilantro taste. The fragrant aroma awakens the senses and provides an elegant flourish to your dish.",
      },
      {
        key: 2,
        picture: LemonBasil,
        price: "25",
        totalPrice: "0",
        quantity: "0",
        name: "Lemon Basil",
        description:
          "Vibrant and aromatic, lemon basil microgreens offer a tantalizing citrusy fragrance and the flavor intertwines classic basil’s peppery notes with a burst of lemony brightness.",
      },
      {
        key: 3,
        picture: ItalianBasil,
        price: "25",
        totalPrice: "0",
        quantity: "0",
        name: "Italian Basil",
        description:
          "Italian Basil microgreens have a depth of flavor that is intriguing, versatile and distinctive. Each peppery bite is underscored by a subtle anise-like warmth, inviting you to expand your creativity.",
      },
      {
        key: 4,
        picture: LittleGemLettuce,
        price: "25",
        totalPrice: "0",
        quantity: "0",
        name: "Little Gem Lettuce",
        description:
          "Little Gem is our star baby green. This petite and elegant lettuce offers a striking visual appeal, boasting leaves that are a vibrant green, with a hint of jade. The tender, buttery softness is apparent in the look and mouth feel that delivers a fresh clean experience.",
      },
    ],
    [
      {
        key: 1,
        picture: wasabiplaceholder,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Wasabi",
        description:
          "This miniature marvel is a mustard micro that captivates the palate with a fresh green flavor that sneaks up on your senses, as a smooth yet powerful heat emerges.",
      },
      // {
      //   key: 2,
      //   picture: EasternShoreSpecial,
      //   price: "5",
      //   totalPrice: "0",
      //   quantity: "0",
      //   name: "Eastern Shore Special",
      //   description:
      //     "This mini mustard green has a distinctive spicy citrus flavor with a strong kick that commands your attention. Calling to mind seafood dishes such as crabcakes and scallops, the complex flavor is assertive and elevates a dish with its bold presence.",
      // },
      {
        key: 2,
        picture: PurpleKohlrabi,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Purple Kohlrabi",
        description:
          "This kohlrabi is a beautifully purple, enchanting gem. The delicate but rich cabbage nuanced flavor is subtle yet dances across the tongue.",
      },
      {
        key: 3,
        picture: Beet,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "ZEN Beets Confetti",
        description:
          "The mesmorizing color COMBINATION of Bull's Blood Red Beets + Yellow Beets yields our creation that we call confetti. Microgreens are delivered PRE-MIXED with a balance of both red and yellow hues. Enhance your plating with the subtle earthy flavor of the beet microgreen, their gorgeous leaves and stems will be sure to properly adorn your dish.",
      },
      {
        key: 3,
        picture: TendrilP,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Tendril Peas",
        description:
          "The intricate tendrils and soft green leaves have an elegant visual appeal. The stems provide a satisfying crunch, followed by a slightly sweet burst of flavor that is reminiscent of garden-fresh peas.",
      },
      // {
      //   key: 5,
      //   picture: RedBeet,
      //   price: "5",
      //   totalPrice: "0",
      //   quantity: "0",
      //   name: "Red Beet",
      //   description:
      //     "The mesmorizing color COMBINATION of Bull's Blood Red Beets + Yellow Beets yields our creation that we call confetti. Microgreens are delivered PRE-MIXED with a balance of both red and yellow hues. Enhance your plating with the subtle earthy flavor of the beet microgreen, their gorgeous leaves and stems will be sure to properly adorn your dish.",
      // },
      {
        key: 4,
        picture: SouthernHospitality,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Southern Hospitality",
        description:
          "This turnip micro has an understated earthiness that provides a wide range of versatility. Reminiscent of a lazy Sunday in the kitchen, this flavor feels like a big ole hug, y'all!",
      },
      {
        key: 5,
        picture: AsianFusion,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Asian Fusion",
        description:
          "Our mix of Tatsoi & Wasabi harmonizes to strike a perfect balance of subtle spinach and heat, which provides versatility in the dishes it can serve as a base to create.",
      },
      {
        key: 6,
        picture: WellnessMix,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Wellness Mix",
        description:
          "Wellness Mix is a vibrant expression of arugula's distinctive peppery flavor and the rich, robust, earthy undertones of kohlrabi. A fresh bright green hue mixes with stunning purple to produce a colorful and versatile ELF Microgreens custom mix.",
      },
      // {
      //   key: 9,
      //   picture: ThePerfectTopping,
      //   price: "5",
      //   totalPrice: "0",
      //   quantity: "0",
      //   name: "The Perfect Topping",
      //   description:
      //     "Cabbage, arugula and mustard dance together as a symphony of flavors, with a peppery bite that mellows into a homestyle cabbage hug for your tongue.",
      // },
      {
        key: 7,
        picture: SandwichStuffer,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Sandwich Stuffer",
        description:
          "My secret mix of mustard micro seeds is a crowd favorite. This petite powerhouse has a flavor reminiscent of the fiery & zesty zing of mustard. The flavor complements a dish without being overpowering.",
      },
      {
        key: 8,
        picture: DragonsLairRadish,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Dragon's Lair Radish",
        description:
          "This mini masterpiece is no mere garnish. Dragon’s Lair Radish captivates your attention with tempting color as it elevates a farm fresh dish with a surprising spicy kick, loved by adults and kids alike.",
      },
      {
        key: 9,
        picture: purpleReignRadish,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Purple Reign Radish",
        description:
          "Our resplendent Purple Reign radish is a gastronomic delight for it’s rock star color, crisp yet yielding texture, and lingering distinctive radish taste.",
      },
      {
        key: 10,
        picture: SaladMix,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Salad Mix",
        description:
          "Salad mix is a party for your taste buds, as broccoli, arugula, kale, kohlrabi, cabbage and mustard, mix to create a gentle, very vegetal flavor.",
      },
      {
        key: 11,
        picture: VeggieMix,
        price: "5",
        totalPrice: "0",
        quantity: "0",
        name: "Veggie Mix",
        description:
          "Our broccoli and brussels mix reveals its earthy essence with a rich, versatile, layered flavor that allows each veggie to shine.",
      },
      // {
      //   key: 14,
      //   picture: BroccoliBliss,
      //   price: "5",
      //   totalPrice: "0",
      //   quantity: "0",
      //   name: "BroccoliBliss",
      //   description:
      //     "With our broccoli, there is no overpowering brassica bite. Instead, each morsel has a mild caress for your tongue. Far from being course, the texture is tender and alluring, which makes for imaginative play in the kitchen.",
      // },
    ],
  ];
  return flavorPicList;
};

export default LoadGreens;
